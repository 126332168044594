window._ = require("lodash");
window.Popper = require("popper.js");

try {
  window.$ = window.jQuery = require("jquery");

  require("bootstrap");
} catch (e) {}

//lozad
var lozad = require("lozad");
const observer = lozad(); // lazy loads elements with default selector as '.lozad'
observer.observe();

require("jquery-slimscroll");
(function() {
  "use strict";
  window.addEventListener(
    "load",
    function() {
      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      var forms = document.getElementsByClassName("needs-validation");
      // Loop over them and prevent submission
      var validation = Array.prototype.filter.call(forms, function(form) {
        form.addEventListener(
          "submit",
          function(event) {
            if (form.checkValidity() === false) {
              event.preventDefault();
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    false
  );
})();
